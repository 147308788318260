import Vue from "vue";
import Router from "vue-router";

// Importar las vistas
import home from "../views/home.vue";
import pricing from "../views/pricing.vue";
import Register from "../views/demo.vue";

import Terms from "../views/terms.vue";
import Privacy from "../views/privacy.vue";


Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: home,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: pricing,
    },
    {
      path: "/Register",
      name: "Register",
      component: Register,
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy,
    }
  ],
});

export default router;
