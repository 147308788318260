<template>
    <div>
        <v-app>
            <Header />
            <v-main
                style="padding: 0px 0px 0px; background-image: linear-gradient(to bottom, #000000, #000000,  #000000, #000000,  #636363, #ffffff);">
                <v-container style="margin-top: 130px; margin-bottom: 200px; text-align: center;">
                    <center>
                        <p class="intro-text-head">ChatPro AI <v-btn rounded outlined color="#7579e7">NEW<v-icon right>mdi-creation</v-icon></v-btn> version is coming!</p>
                        <br>
                        <iframe src="https://tally.so/embed/mYxMez?hideTitle=1&dynamicHeight=1" class="hidden-sm-and-down" style="border:0px #ffffff none; border-radius: 20px;" name="ChatPro AI Waitlist" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="650px" width="500px" allowfullscreen></iframe>
                        <iframe src="https://tally.so/embed/mYxMez?hideTitle=1&dynamicHeight=1" class="hidden-md-and-up" style="border:0px #ffffff none; border-radius: 20px;" name="ChatPro AI Waitlist" scrolling="no" marginheight="0px" marginwidth="0px" height="650px" width="380px" allowfullscreen></iframe>

                    </center>
                </v-container>

            </v-main>
            <Footer />

        </v-app>
    </div>
</template>

<script>
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'
// import howItWorks from '@/components/components_landing_home/howItWorks.vue'
// import Assistant from '@/components/components_landing_home/customAsisstant.vue'

// @ is an alias to /src
export default {
    name: 'termsView',
    components: {
        Header,
        Footer,
        // howItWorks,
        // Assistant
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register")
        }
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: #ffffff;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>